import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpClientOptionsService } from '../../authentication/services/http-client-options.service'
import { environment } from '../../../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class FileStorageService {
    constructor(
        private httpClient: HttpClient,
        private httpClientOptionsService: HttpClientOptionsService,
    ) {}

    /**
     * 	OBS: The 'promptSaveAs' is the filename to prompt the user to save the file as when the signed url is accessed.
     */
    getFileTemporaryUrl(storageType: string, fileId: string, promptSaveAs?: string) {
        let url = `${environment.URL_BASE}/partnerhub/storage/get-public-file-url/${fileId}/${storageType}`
        if (promptSaveAs) url += `?promptSaveAs=${promptSaveAs}`

        return this.httpClient.get<any>(
            url,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    uploadFile(formData: FormData) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/storage/upload-file`,
            formData,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    getUploadUrl(storageType: string) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/storage/get-signed-upload-url/${storageType}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }

    uploadFileViaSignedUrl(url: string, file: FormData) {
        const rawFile = file.get('file') as File
        let headers = new HttpHeaders({
            'Content-Type': rawFile.type,
        })

        return this.httpClient.put(url, file, {
            reportProgress: true,
            observe: 'events',
            headers: headers,
        })
    }

    uploadFileViaSignedUrlUsingBinary(url: string, file: File) {
        let headers = new HttpHeaders({
            'Content-Type': file.type,
        })

        return this.httpClient.put(url, file, {
            reportProgress: true,
            observe: 'events',
            headers: headers,
        })
    }

    getFile(storageType: string, fileId: string) {
        return this.httpClient.get<any>(
            `${environment.URL_BASE}/partnerhub/storage/get-file/${storageType}/${fileId}`,
            this.httpClientOptionsService.getPartnerhubAuthorization(),
        )
    }
}
